import React from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { fallDown as BurgerMenu } from 'react-burger-menu'
import './BAMenuStyle.css'
import Follow from '../Social/Social'

const withLocation = Component => props => {
    const location = useLocation();

    return <Component {...props} location={location} />;
};

class BAMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false
        }
    };
  
    getCurrentPage() {

        //can't use advanced syntax because of google bot
        var defaultPage = null;

        for (var i = 0; i < this.props.menus.length; i++) {
            var item = this.props.menus[i]
            if (item.isDefaultPage)
                defaultPage = item;

            if (item.path === this.props.location.pathname)
                return item;
        }
        return defaultPage;

    }
   
    handleStateChange() {
        var page = this.getCurrentPage()
        this.props.onMenuChange(page)
        document.title = page.title
    }

    render() {

        const result = this.props.menus.filter(item => item.menuHide !== true);
        const buttons = Object.keys(result).map((menu) => {

            if (menu.menuHide === true)
                return ("")

            return (
                <NavLink key={menu} to={this.props.menus[menu].path} 
                  
                >
                    {this.props.menus[menu].icon}
                    {this.props.menus[menu].buttonText}
                </NavLink>

            );
        })

        return <div style={{ height: "70px" }}  >
            <BurgerMenu pageWrapId={"page-wrap"} outerContainerId={"outer-container"}  
             isOpen={false} 
             onStateChange={(state) => this.handleStateChange(state)}
               >
                {buttons}
                <br />
                <br />
                <p className="bm-title">Follow me at</p>
                <Follow />
            </BurgerMenu >
        </div>
    }
}


export default withLocation(BAMenu)
