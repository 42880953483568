/* https://github.com/nozzle/react-static/blob/master/docs/guides/dynamic-routes-reach-router.md */

//base modules
import React from 'react'
import {
    Routes,
    Route,
} from "react-router-dom";

import ChatPlayer from "./pages/chat-player";
import OutdoorNavigator from './pages/outdoor-navigator'
import About from './pages/about'
import PrivacyPolicy from './pages/privacy-policy'

import P404 from './pages/404'

//3 party modules
import { IoLogoAndroid, IoLogoApple } from 'react-icons/io'
import CoockieConsentContainer from './modules/CookieConsent/CookieConsent'
import Follow from './modules/Social/Social'
import TopBar from './modules/TopBar/TopBar'
//project modules
import BAMenu from './modules/BAMenu/BAMenu'

//style
import './App.css'

let Menu = BAMenu;


const menus = [
    { title: "Chat Player App", descr: "Watch videos with friend at chat rooms", buttonText: 'Chat Player', icon: <IoLogoAndroid />, path: '/chat-player', isDefaultPage: true, index: true },
    { title: "Outdoor Navigator App", descr: "A fancy compass, GPS based, that can give you direction and distance anytime, connected or not.", buttonText: 'Outdoor Navigator', icon: <IoLogoApple />, path: '/outdoor-navigator', index: true },
    { title: "About", descr: "About Byte Artisan work.", buttonText: 'About', path: '/about', index: true },
    { title: "Privacy Policy", descr: "Privacy Policy for byte artisan site", buttonText: 'Privacy Policy', path: '/privacy-policy', menuHide: false, index: false },
];
const ReadSitePrivacyPolicy = () => (
    <p>Read the site <a href="/privacy-policy">Privacy policy</a></p>
)

function Footer({ children }) {
    return (
        <div className="container container-footer">

            <Follow />

            <p>Copyright &#169;2019 Byte Artisan, All Rights Reserved.</p>
            <ReadSitePrivacyPolicy />
            {children}
        </div>
    )
}


class App extends React.Component {

  
    componentDidMount() {
        window.addEventListener('load', this.handleLoad);
        document.getElementById("header")
    }
    componentWillUnmount() {
        window.removeEventListener('load', this.handleLoad)
    }


    changeMenu(menu) {
        this.setState({ currentMenu: menu });
    }

    CoockieConsentCheck() {

        return <CoockieConsentContainer

             onAccept={() => {
               //  dataLayer.push({
               //      'event': 'acceptedCookie'
               //  })
             }}

      

            privacyPage={'/privacy-policy'}
        />
    }

    render() {

        return (
            <div className="wrapper">
                <div id="outer-container">
                    <header id="header" className="header" style={{height:"70px"}}>
                    <TopBar menus={menus}/>
                      
                        {this.CoockieConsentCheck()}
                    </header>
                    <main id="page-wrap" >
                       
                        <Routes>
                            <Route element={<ChatPlayer />} path="/" priority={1.0} />
                            <Route element={<ChatPlayer />} path="/chat-player" priority={1.0} />
                            <Route element={<OutdoorNavigator />} path="/outdoor-navigator" priority={1.0} />
                            <Route element={<About />} path="/about" priority={0.1} />
                            <Route element={<PrivacyPolicy/>} path="/privacy-policy" noindex={true} />

                            <Route
                                path="*"
                                element={<P404 />}
                            />

                        </Routes>
                    </main>
                    <Footer />
                </div>

            </div>
        )
    }

}

export default App
