import React from 'react'
import PageRow from '../modules/PageResponsive/PageResponsive'

const OutdoorNavigator = () => (
    <div >

        <PageRow
            left={

                <div className="appIntro">

                    <div className="appIntro-icon">

                        <img alt="Outdoor navigator icon" src={require('../images/outdoorNavigator/outdoorNavigator250.gif')} />
                    </div>
                </div>
            }
            right={

                <div className="appIntro">

                    <div className="appIntro-text-box" >
                        <p className="problem-text">
                            What if you had a compass that can point to your destination?
                </p>
                        <br />
                        <p className="problem-solution">
                            <strong>Outdoor Navigator</strong> is just that.
                        </p>
                        <br />
                        <p>
                            A fancy 3D compass, GPS based, that can give you direction and distance anytime, connected or not.
                    </p>
                    </div>

                </div>

            }
        />
        <PageRow
            center={
                <div className="center-text">
                    <h2>Outdoor Navigator - Offline GPS Spot Finder</h2>
                    <p>
                        <strong>Outdoor Navigator</strong> can point to any spots at open areas like rural regions, concerts, sea or other uncharted areas just by following the 3D fancy Arrow.</p>
                    <p>
                        Enter the coordinates off-line or online and let the compass arrow guide you to your destiny.
            </p>
                </div>
            }
        />

        <PageRow center={
            <div style={{ textAlign: "center" }}>
                <a rel="noopener" href="https://itunes.apple.com/pt/app/void-navigator-offline-gps/id1018620502?mt=8" target="_blank" title="Go to Apple Store">
                    <img alt="click here to download from apple store"
                 src={require('../images/outdoorNavigator/Download_on_the_App_Store_Badge_US-UK_135x40.svg')} /></a>
            </div>
        } />


        <PageRow center={
            <div>
                <h2>
                    Screen shoots
             </h2>

                <table>
                    <tbody>
                        <tr>
                            <td className="screenShootImg" >
                                <img alt="starting page" src={require('../images/outdoorNavigator/navigator_site1.jpg')} />
                            </td>
                            <td className="screenShootImg" >
                                <img alt="navigation page" src={require('../images/outdoorNavigator/navigator_site2.jpg')} />
                            </td>
                            <td className="screenShootImg">
                                <img alt="set destination" src={require('../images/outdoorNavigator/navigator_site3.jpg')} />
                            </td>
                        </tr>
                        <tr>
                            <td className="screenShootText" >
                                <p>3D fancy arrow points to your destination.</p>
                                <p>The arrow can floats like if is on the water.</p>
                                <p>Destination Spot can be entered manually just by tapping the destination.</p>
                                <p>Keeps track of where you are, showing current GPS coordinates or address if online</p>
                                <p>Works offline and online. </p>
                            </td>
                            <td className="screenShootText" >
                                <p>The map view show what is in front of you.</p>
                                <p>It keeps track of your orientation and rotates to keep to align with your perspective.</p>
                                <p>Here you can also enter your destination spot by just holding the position with your finger.</p>
                                <p>Works online only.</p>
                            </td>
                            <td className="screenShootText"  >
                                <p>Search your spot and set it as your destination using map search.</p>
                                <p>Works online only. </p>
                            </td>
                        </tr>
                    </tbody>
                </table >
            </div>
        }
        />
        <PageRow
            center={
                <div>
                    <h3>Proper for situations where</h3>
                    <ul>

                        <li>Internet is not accessible;</li>
                        <li>Area is uncharted;</li>
                        <li>Maps are not practical, e.g, finding someone or some place in 1 or 2 blocks distance having the direction and distance is enough;</li>
                        <li>Area is charted but there are no roads or other references like at sea or woods.</li>
                    </ul>
                    <br />
                    <strong>Outdoor Navigator</strong> also can give you a map, always updated, showing your current location and destiny, as long you stay connected.

         <div>
                        <h2>Features</h2>
                        <ul>
                            <li>Point to destination Spot, in coordinates.A 3D Arrow will give the direction of your destination as you go;</li>
                            <li>Floating 3D Arrow. The 3D needle keeps pointing to the
            spot in the horizontal plan regardless the iPhone orientation, like if it is floating on water;</li>
                            <li>Spot Archive. Save your spots to load later (payed feature);</li>
                            <li>Set destination off-line. Enter coordinates manually;</li>
                            <li>Set destination on-line. Enter coordinates by using a map or a satellite view;</li>
                            <li>Returning point. Use current location as the destination;</li>
                            <li>Track location. Show current coordinates and current address as you go;</li>
                            <li>Search POI and locations near by;</li>
                            <li>Automatic coordinate to address translation;</li>
                            <li>Compass. A small compass always points to the North;</li>
                            <li>Map follows user position and rotates automatically to align with user direction.</li>
                            <li>Share Locations. Post your location or any previous saved location to any sharing services;<br />
                                Apple maps links included in shares by e-mail);</li>
                            <li>Read coordinates in text shared from other apps. </li>
                        </ul>
                    </div>
                </div>
            }
        />

    </div>

)

export default OutdoorNavigator