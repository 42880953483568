import React from 'react';
import MediaQuery from 'react-responsive';

import './PageResponsive.css'

class PageRow extends React.Component {

    constructor(props) {
        super(props);
    };

    render() {

        var center = null;

        if (this.props.center != null)
            center = <div className="container">{this.props.center}</div>;

        var rowRacio = 0.5
        if (this.props.hasOwnProperty('rowRacio'))
            rowRacio = this.props.rowRacio;

        var leftWith = rowRacio * 100 + "%";
        var rightWith = (1 - rowRacio) * 100 + "%";

        var leftRightFlow = true
        if (this.props.hasOwnProperty('leftRightFlow'))
            leftRightFlow = this.props.leftRightFlow;

        var flow = null
        var row = null

        if (this.props.left || this.props.right) {

            if (!leftRightFlow) {
                flow = <MediaQuery query="(max-device-width: 660px)" className="row-small" >
                    <div className="container">{this.props.right}</div>
                    <div className="container">{this.props.left}</div>
                </MediaQuery>
            } else {
                flow = <MediaQuery query="(max-device-width: 660px)" className="row-small" >
                    <div className="container">{this.props.left}</div>
                    <div className="container">{this.props.right}</div>
                </MediaQuery>
            }

            row = <div>
                <MediaQuery query="(min-device-width: 660px)" >
                    <div className="row-large">
                        <div className="container container-left" style={{ width: leftWith }}>{this.props.left}</div>
                        <div className="container container-right" style={{ width: rightWith }}>{this.props.right}</div>
                    </div>
                </MediaQuery>

            </div>
        }
        return (
            <div>
                {row}
                
                {flow}
          
                {center}
                <div className="separator-horizontal" />
            </div>

        )
    }
}

export default PageRow;