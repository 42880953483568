import React from 'react'

const p404 = () => {

    return (
        <div>
            <h1>404 - Oh no's! We couldn't find that page :(</h1>
        </div>
    )
}


export default p404