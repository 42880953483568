import React from 'react'
import './about.css'

import PageRow from '../modules/PageResponsive/PageResponsive'

import personal3dfabIcon from '../images/personal3dfab/personal3dfab.jpg'
import chatPlayerIcon from '../images/chatPlayer/chatPlayer512x512.png'
import outDoorNavigatorIcon from '../images/outdoorNavigator/outdoorNavigator250.gif'
import guiaCondominioIcon from '../images/guiaCondominio/guiaCondominioLogo.gif'


const About = () => (

    <div>

        <PageRow
            center={
                <div>
                    <h1>About</h1>

                    <p><strong>Byte artisan</strong> is my nick name for signing work that i put online. This site is dedicated to provide technical and marketing support for the work i have done and will do.</p>
                    <h2 style={{ float: "right" }}>Work log</h2>
                    <br />
                    <br />
                </div>
            }
        />
        <PageRow
            center={

                <div>
                    <img alt="Chat Player" src={chatPlayerIcon} className="icon" /> <h3>Chat Player (2019) </h3>
                    <p>
                        Chat app to watch videos together
                    </p>
                    <p>
                        The app runs on iOS
                    </p>
                    <p>
                        This app was discontinued.
                    </p>


                </div>
            }
        />
        <PageRow
            center={
                <div>

                    <h3>

                        <img alt="Outdoor Navigator icon" src={outDoorNavigatorIcon} className="icon" /> Outdoor Navigator (2015)
                    </h3>
                    <p>
                        A fancy compass, GPS based, that can give you direction and distance anytime, connected or not.
                    </p>
                    <p>
                        The app runs on iOS
                    </p>
                </div>
            }
        />
        <PageRow
            center={
                <div>
                    <h3>
                        <a href="http://byte-artisan.com/personal3dFab/"> <img alt="" src={personal3dfabIcon} className="icon" /> Personal 3D Fab (2014)</a>
                    </h3>
                    <p>
                        At 2015 i and a friend of my dedicated some of our time to create a site intended to help people to enter the world of personal fabrication, using the last and amazing tools and fabrication services available from everywhere. 3D Printing technology is advancing very fast and new ideas and appliances are appearing every day.
                    </p>
                </div>
            }
        />
        <PageRow
            center={
                <div>
                    <h3>
                        <a href="https://guiacondominio.com/language/pt-PT/pt/Condominio">
                            <img alt="Guia condominio" src={guiaCondominioIcon} className="icon" /> Guia Condominio (2009)</a>
                    </h3>
                    <p>
                        This is my oldest work, a site dedicated to HOA management for condominiums. The main role is to provide a simple and comprehensive guidance through administrative tasks, for managing condominiums administrated by Homeowners
                    </p>
                </div>
            }
        />
    </div>
)

export default About