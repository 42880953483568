import React from 'react'
import ImageGallery from 'react-image-gallery';
import "./SlideShow.css";

class SlideShow extends React.Component {

    constructor(props) {
        super(props);
        this.slidesArray = props.slidesArray;  
        this.autoPlay = true;
    };

    //example :https://github.com/xiaolin/react-image-gallery/blob/master/example/app.js#L147
    _onImageClick(event) {

        this.autoPlay = !this.autoPlay

        if (this.autoPlay)
            this._imageGallery.play();
        else
            this._imageGallery.pause();
       
    }
    
    render() {
        
        return (
            <ImageGallery
                ref={i => this._imageGallery = i}
                items={this.slidesArray}
                showThumbnails={false}
                showFullscreenButton={false}
                showPlayButton={false}
                autoPlay={this.autoPlay}
                onClick={this._onImageClick.bind(this)}
            />
        );
    }

}

export default SlideShow