//https://github.com/holidaycheck/react-google-tag-manager#readme

import React from 'react';
import { SocialIcon } from 'react-social-icons';

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
} from 'react-share';

import './Social.css'

class Follow extends React.Component {

    handleLoad() {

        window.onerror = function (message, url, lineNo, colNo, error) {
            //set email
            var s = 'moc.kooltuo@nasitra-etyb';
            var e = s.split("").reverse().join("");
            document.getElementById("email").innerHTML = '<a href="mailto:' + e + '">' + e + '</a>';
        }
    }

    render() {
        return (
            <div className="follow">
                < span id="email" className="email" ></span >

                <a href="https://byte-artisan.blogspot.com/" className="social-icon social-icon-fix" >
                    <img src={require('./blogger.png')} alt="blogger" />
                </a>
                <SocialIcon url="https://www.youtube.com/channel/UCOWUcdM9e3Mdd-524rRLeug/playlists" />
                <SocialIcon url="https://www.facebook.com/byteartisan/" />
                <SocialIcon url="https://twitter.com/ArtisanByte" />

            </div>
        );
    }
}


export class Share extends React.Component {

    render() {
        return (

            <div className="share" >
                <div className='hover-button'>
                 
                    <div className='hover-button--on'>
                        <div className="share-container">
                            <FacebookShareButton url="https://www.facebook.com/byteartisan/" quote="teste quote" >
                                <FacebookIcon round size={30}/>
                            </FacebookShareButton>
                            <span>Share on Facebook</span>
                        </div>
                        <div className="share-container">
                            <TwitterShareButton url="https://twitter.com/ArtisanByte" quote="test quote">
                                <TwitterIcon round size={30}/>
                            </TwitterShareButton>
                            <span>Share on Twitter</span>
                        </div>
                      
                    </div>
                    <div className='hover-button--off'>
                        <img src={require('./share.webp')} alt="share" />
                        <span>Share</span>
                    </div>
                </div>
            </div >
        );
    }
}

export default Follow