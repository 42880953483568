import React from 'react';
import Cookies from 'universal-cookie';
import { useLocation,useNavigate  } from 'react-router-dom'
import './CookieConsent.css'

const withRouter = Component => props => {
    const navigation = useNavigate();
    const location = useLocation();
    return <Component {...props} navigation={navigation} location={location} />;
  };
  
class CookieConsent extends React.Component {

    constructor(props) {
        super(props);

        this.handleAcceptClick = this.handleAcceptClick.bind(this);
        this.privacyPage = props.privacyPage
        this.onChange = () => { if (props.onChange != null) props.onChange() };
    };

    componentDidMount_w() {
        this.props.navigation.listen((location, action) => {
            this.forceUpdate()
        });       
    }   

    componentDidUpdate() {
        this.onChange()
    }
     
    handleAcceptClick() {
        CookieConsent.set(true)
        this.props.onAccept()
        this.forceUpdate()
    }

    static set(val) {

        let d = new Date();
        d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));

        const cookies = new Cookies();
        cookies.set('userConsent', val, { path: '/', expires: d });
        this.isDirty = true;
    }

    static userConsented() {
        const cookies = new Cookies();
        var val = cookies.get('userConsent') === 'true'
        return val;
    }

    static toogle() {
        this.set(!this.userConsented())
    }
    

     ReadMoreButton = withRouter(({ history }) => (
        <button
            type='button'
            onClick={() => {  
                this.props.navigation(this.props.privacyPage, { replace: false });
             }}
        >
            Learn more
        </button>
    )) 



    render() {
        
        if (this.props.location.pathname === this.privacyPage)
            return null
        if (CookieConsent.userConsented())
            return null;

        return (
            <div id="cookieConsent">
                <ul id="cookieConsent-buttons">
                    <li>
                        <button onClick={this.handleAcceptClick}>
                            I am happy with it
                             </button>
                    </li>
                    <li>
                        <this.ReadMoreButton />
                    </li>
                </ul>
                <div>
                    This site collects usage data for maintenance and improve user experience.
                </div>

            </div>
        )
    }
}

class CookieSettings extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = { checked: CookieConsent.userConsented() };
    }

    handleClick() {

        CookieConsent.toogle()
        this.setState((state) => ({ checked: CookieConsent.userConsented() }));
    }

    render() {

        return (
            <ul className="options">
                <li>
                    <label className="chk-container">
                        Essentials
                    <input type="checkbox" checked={true} disabled />
                        <span className="checkmark"></span>

                    </label>
                </li>                
            </ul>
        )
    }
}


export default withRouter(CookieConsent)
export { CookieSettings }


/*
<li>
                    <label className="chk-container">
                        Analytics
                    <input type="checkbox" checked={this.state.checked} onChange={this.handleClick} />
                        <span className="checkmark"></span>

                    </label>
                </li>
*/