import React from 'react';
import MediaQuery from 'react-responsive';
import BAMenu  from '../BAMenu/BAMenu';
import './styles.css'

class TopBar extends React.Component {
  
    constructor(props) {
        super(props);
        this.menu = React.createRef();
        this.onMenuChange = this.onMenuChange.bind(this);
        this.state = {
            icon:null,
            title:null,
        }
    }
    
    onMenuChange(page){
        this.setState({title:page.title,icon:page.icon22})
    }
    render() {

        return <div id="top-bar" className="top-bar">
            <BAMenu menus={this.props.menus} onMenuChange={this.onMenuChange}  />
            <MediaQuery query="(max-device-width: 660px)" className="row-small" >
                <h1 style={{ fontSize: "large" }}>{this.state.icon} {this.state.title}</h1>
                <div className="slogan">
                <span style={{ fontSize: "small",margin:"5px" }}>Apps handmade for you</span> 
            </div>
            </MediaQuery>
            <MediaQuery query="(min-device-width: 660px)" className="row-small" >
                <h1>{this.state.icon} {this.state.title}</h1>

                <div className="slogan" style={{ marginRight:"5px" }}>
                <span >Apps handmade for you</span> 
            </div>
            </MediaQuery>

            
        </div>
    }
}

export default TopBar

