import React from 'react'
import PageRow from '../modules/PageResponsive/PageResponsive'
import { CookieSettings } from '../modules/CookieConsent/CookieConsent'
const cookieConsentRevoqueStyle = {
    float: 'right',
};

class PrivacyPolicy extends React.Component {

    render() {
        return (
            <PageRow
                center={
                    <div>

                        <div style={cookieConsentRevoqueStyle} >
                            <CookieSettings />
                        </div>
                        <h1>Privacy Policy</h1>

                        <p>Effective date: April 12, 2019</p>


                        <p>Byte Artisan ("us", "we", or "our") operates the https://byte-artisan.com/ website (the "Service").</p>

                        <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. Our Privacy Policy  for Byte Artisan is created with the help of the <a href="https://www.freeprivacypolicy.com/">Free Privacy Policy website</a>.</p>

                        <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from https://byte-artisan.com/</p>


                        <h2>Information Collection And Use</h2>

                        <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

                        <h3>Types of Data Collected</h3>

                        <h4>a) Personal Data</h4>

                        <p>We only collect personal when you contact us</p>
                        <p>The information we keep is the contact information you provide, like the email address you use to contact us, as well the messages exchanged.</p>
                        <p>All messages and contacts will be keep for 2 years to assurance continuity of the service support you may request.</p>

                        <h4>b) Usage Data (Essencial data)</h4>

                        <p>We collect information how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit and other diagnostic data.</p>
                        <p>This information is essencial to keep the site alive, protect against attacks, to detect, prevent and address technical issues. For these reasons the collect of this data can't be turn off.</p>
                        <p>Usage Data is purged from the systems as soon they are no longer needed to maintain then.</p>

                        <h4>c) Analytics Data</h4>

                        <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
                        <p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>

                        <p>Examples of Cookies we use:</p>
                        <ul>
                            <li><strong>Session Cookies.</strong> We use Session Cookies to operate our Service. This kind of cookies can't be disable.</li>
                            <li><strong>Preference Cookies.</strong> We use Preference Cookies to remember your preferences and various settings. This enables us to save your privacy settings.</li>
                        </ul>

                        <h2>Transfer Of Data</h2>

                        <p>Your information, including Personal Data, may be transferred to - and maintained on - computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
                        <p>If you are located outside Portugal and choose to provide information to us, please note that we transfer the data, including Personal Data, to Portugal and process it there.</p>
                        <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
                        <p>Byte Artisan will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>

                        <h2>Disclosure Of Data</h2>

                        <h3>Legal Requirements</h3>
                        <p>Byte Artisan may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
                        <ul>
                            <li>To comply with a legal obligation</li>
                            <li>To protect and defend the rights or property of Byte Artisan</li>
                            <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                            <li>To protect the personal safety of users of the Service or the public</li>
                            <li>To protect against legal liability</li>
                        </ul>

                        <h2>Security Of Data</h2>
                        <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

                        <h2>Links To Other Sites</h2>
                        <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>


                        <h2>Children's Privacy</h2>
                        <p>Our Service does not address anyone under the age of 18 ("Children").</p>
                        <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>

                        <h2>Service Providers</h2>
                        <p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), provide the Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</p>
                        <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

                        <h3>Currently used third party providers</h3>
                        <ul>
                            <li>
                                <p><strong>Vercel</strong></p>
                                <p>This site is hosted on the Vercel platform. As hosting service, it process the information of end users when they use the site. This information may also include, but is not limited to, IP addresses, system configuration information, and other information about traffic to and from the sites (collectively, "Log Data").</p>
                                <p> This information is stored by Vercel as part of the service. Regarding the Log Data, these are collected and used to operate, maintain and improve the services provided in the performance of the service provided by Vercel. For example, Log Data can help us detect new threats, identify malicious third parties and provide more robust security protection for the site. It also helps us to improve Vercel's own service.

                                    For more information on the privacy practices of Vercel, please visit the Vercel Privacy & Terms 
                                    web page:<a href="https://vercel.com/legal/privacy-policy">https://vercel.com/legal/privacy-policy</a>

                                    </p><p>This tool is necessary to operate and for that it can't be turned off.</p>
                            </li>
                        </ul>
                        <h2>Changes To This Privacy Policy</h2>
                        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                        <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
                        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>


                        <h2>Contact Us</h2>
                        <p>If you have any questions about this Privacy Policy, please use contacts on this site.</p>
                    </div>
                }
            />
        )
    }
}
export default PrivacyPolicy