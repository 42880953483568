import React from 'react'

import SlideShow from '../modules/SlideShow'
import PageRow from '../modules/PageResponsive/PageResponsive'

import appIcon from '../images/chatPlayer/chatPlayer150x150.png'
import { Share } from '../modules/Social/Social';


const slidesArray = [

    {
        originalTitle: 'Watch a concert',
        original: require('../images/chatPlayer/1.gif'),
    },
    {
        originalTitle: 'Watch a movie with your pair',
        original: require('../images/chatPlayer/2.gif'),
    },
    {
        originalTitle: 'Own private chats for you and your friends',
        original: require('../images/chatPlayer/3.gif'),
    },
    {
        originalTitle: 'Search YouTube for any kind of videos',
        original: require('../images/chatPlayer/4.gif'),
    },
    {
        originalTitle: 'Cozy with your friends sharing the screen',
        original: require('../images/chatPlayer/5.gif'),
    },
    {
        originalTitle: 'Have fun online partys watching lol videos',
        original: require('../images/chatPlayer/6.gif'),
    }
]

const ChatPlayer = () => (
    <div>
        <PageRow

            left={
                <div className="appIntro">
                    <div className="appIntro-icon" >
                        <img alt="Chat Player icon" src={appIcon} />
                    </div>
                </div>
            } right={
                <div>
                  
                     <div className="appIntro-text-box" >
                        <h1>Chat Player</h1>
                        <p className="problem-text">
                            What if you could watch a video with friends that aren't there with you?
                         </p>
                        <br />
                        <p className="problem-solution">
                            Now you can!
                        </p>
                        
                    </div>
                   
                </div>
            }
        />
        <PageRow
            center={
                <div className="center produt-links">
                    <a href="https://play.google.com/store/apps/details?id=com.byte_artisan.mchat2">
                        <img alt="Get it on Google Play"
                            src="https://developer.android.com/images/brand/en_generic_rgb_wo_60.png" />
                    </a>
                    <div>
                        <Share />
                    </div>
                </div>
            }
        />


        <PageRow
            leftRightFlow={false}
            left={
                <div>
                    <div>
                        <p>
                            Watching movies or any other kind of videos don't have to be a solitary experience. You can have the best moments with your friends at your chat player rooms, wherever they are.
                        </p>
                        <br />

                        <div>
                            Chat Player brings much more possibilities to a chat party, like:
                        <ul>

                                <li>Watch together movies, music clips, fun videos, etc;</li>
                                <li>Warm up a chat talk with some environment music;</li>
                                <li>Learn, discuss, analyses videos in group.</li>
                            </ul>
                        </div>

                    </div>
                </div>
            }

            right={
                <div className="videoFrame">
                    <div className="videoContainer">
                        <iframe width="560" height="315" title="Chat player demonstration video"
                            src="https://www.youtube-nocookie.com/embed/ESIQ2le009E?controls=0&autoplay=1&mute=1&rel=0" frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                        </iframe>

                       
                    </div>
                </div>

            }
            rowRacio={0.4}
        />

        < PageRow
            left={
                < div className="slideContainer">
                    <div className="slideContainerInner">
                        <SlideShow slidesArray={slidesArray} />
                    </div>
                </div >
            }
            right={
                < div >
                    <div >
                        <p>
                            At Chat Player rooms, video playing is synchronized across all people in the room, and the video is always visible, even when you are typing. Everyone see and listen the same thing at the same time.
                </p>
                        <p>
                            Everyone has the remote and everyone can pause, play, forward and backward the current video in the chat room.
                </p>
                        <p>
                            Chats are permanent, so your friends will be pleased to use then even if you are not there at the time.
                </p>
                        <p>
                            Chat player brings the content you need to cheer up your chat rooms.
                            Only you need more is the pop corns.
             </p>
                        <p>
                            Try it now and offer you and your friends the best chat times at your great chat rooms.
                </p>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div>
                            Features:
                <ul>
                                <li>Create private chats;</li>
                                <li>Invite friends;</li>
                                <li>Play videos inside the Chat rooms;</li>
                                <li>Remote. Pause/play seek commands propagate to with all devices.</li>
                            </ul>
                        </div>
                    </div>
                </div >
            }
            rowRacio={0.4}
        />
    </div >
)

export default ChatPlayer